import "../styles/home.scss";
import "../styles/layout.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeadphones } from "@fortawesome/free-solid-svg-icons";

function Home(props) {
  return (
    <div className="container" onClick={() => props.nextPage()}>
      <div className="home-background"></div>
      <div className="home">
        <FontAwesomeIcon
          className="headset pulse-animation"
          icon={faHeadphones}
        ></FontAwesomeIcon>
        <span className="label">CLIQUE ICI POUR ÉCOUTER LA RADIO</span>
      </div>
    </div>
  );
}

export default Home;
