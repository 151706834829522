import Player from "./components/Player";
import Home from "./components/Home";
import { useState } from "react";

const pages = {
  home: "home",
  player: "player",
};

const App = () => {
  const [page, setPage] = useState(pages.home);

  switch (page) {
    case pages.home:
      return <Home nextPage={() => setPage(pages.player)}/>
      
    case pages.player:
      return <Player/>
  }
}

export default App;
