export default class Radio{
    
    constructor(url, volume){
        this.audio = "";
        this.url = url
        this.urlFake = "https://funados.ice.infomaniak.ch/fake.mp3";
        this.volume = volume;
    }

    async play(){
        if(this.audio.volume === 0){
            this.audio.volume = this.volume;
        }else{
            this.audio.play()
            .catch(err => {
                //message de google chrome remplacé ici
                console.error("Il faut au moins 1 interaction sur la page.","L'autoplay n'est pas possible au chargement de la page !") 
            })
        }
    }
    
    pause(useAppleOS){
        if (!useAppleOS) {
            this.audio.volume = 0
            this.audio.src = this.urlFake
            this.audio.src = this.url
            this.audio.play()
        } else {
            this.audio.src = this.urlFake
            this.audio.src = this.url
        }
    }
    
    updateVolume(volume){
        this.volume = volume;
        this.audio.volume = volume;
    }
    
    async createAudio(){
        this.audio = new Audio(this.url)
        this.audio.volume = this.volume
        console.log(this.audio)
    }

    async canPlay(){
        this.audio.oncanplay = (data) =>{return "data"};
    }

    async autoplay(){
        this.audio.volume = this.volume
        this.audio.play()
    }
}