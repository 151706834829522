import "../styles/layout.scss";
//import Habo from "./Habo";
import Header from "./Header";
import Dedicace from "./Dedicace";
import Background from "./Background";
import Pochette from "./Pochette";
import { useState, useEffect } from "react";
import axios from "axios"
import Radio from '../classes/radioClass'
import socketIOClient  from "socket.io-client"



/**Cookies */
function setCookie(cname, cvalue) {
  var d = new Date();
  d.setTime(d.getTime() + 7 * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}


/** Radio options */
let radioStreamUrl = "https://funados.ice.infomaniak.ch/funados.mp3"

if (typeof com_adswizz_synchro_decorateUrl === "undefined") {
    radioStreamUrl = "https://funados.ice.infomaniak.ch/funados.mp3"
    //adblock on
    //console.log("4. adblock on, ads stream off");
} else {
    radioStreamUrl = window.adswizzSDK.decorateURL(radioStreamUrl)
    //adblock off
    //console.log("4. adblock off, ads stream on");
}


//Audio context
const initialVolume = getCookie("volume");
let vol = initialVolume && initialVolume !== undefined ? initialVolume : 0.1

const radio = new Radio(radioStreamUrl, vol)


function titleAnimation(text, timeOut) {
  document.title = text;
  text = text.substring(1, text.length) + text.charAt(0);
  timeOut = setTimeout(function () {
    titleAnimation(text, timeOut);
  }, 500);
}



function Player() {

  const InitServer = "https://radio-server.funados.fr"
  //const InitServer = 'https://metadata.funados.fr'
  //const InitServer = "http://localhost:4000"
 
  const initData = {
    information: "Chargement en cours..",
    music: {
        artist: "FunAdos",
        title: "La radio officielle d'HabboCity",
        cover: 'https://funados-radio.fr/export/pochette.png',
    },
    history: []
  }
  const [music, setMusic] = useState(initData.music)
  const [history, setHistory] = useState(initData.history)
  const [information, setInformation] = useState(initData.information)

  const [isPlaying, setIsPlaying] = useState(true)
  const [volume, setVolume] = useState(vol)
  const [error, setError] = useState(false)
  const [useAppleOS, setUseAppleOS] = useState(false)
  const [server] = useState(InitServer)
  const [apiUrl] = useState(`${server}/api`)
  


  const updateMusic = async () => {
    await axios
      .get(`${apiUrl}/music`, 
      { headers: {'Access-Control-Allow-Origin': '*'} })
      .then((res) => {
        if (res.data) {
          setMusic(res.data)
          setError(false)
        }
      })
      .catch(_ => {
        setMusic(initData.music)
        setError(true)
      })
  }

  const updateHistory = async () => {
    await axios
      .get(`${apiUrl}/history`, 
      { headers: {'Access-Control-Allow-Origin': '*'} })
      .then((res) => {
        if (res.data) {
          setHistory(res.data)
        }
      })
  }


  const updateInformation = async () => {
    await axios
      .get(`${apiUrl}/information`, 
      { headers: {'Access-Control-Allow-Origin': '*'} })
      .then((res) => {
        if (res.data) {
          setInformation(res.data);
        }
      })
  }


  useEffect(() => {
    updateMusic()
    //updateInformation()
    updateHistory()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  // Gestion de l'autoplay
  useEffect(() => {

    if( navigator.userAgent.match('iPhone')
    || navigator.userAgent.match('iPad')
    || navigator.userAgent.match('iPod')
        ) {
        setUseAppleOS(true)
    }

    //creation du flux audio (radio)
    radio.createAudio()
    radio.play()
    setIsPlaying(true) 
  }, []);



  //sur clique bouton play/pause
  const togglePlaying = async () => {
    if (isPlaying) {
      radio.pause(useAppleOS);
      setIsPlaying(false);
    } else {
      radio.play()
      setIsPlaying(true);
    }
  }



  useEffect(() => {
    radio.updateVolume(volume)
    setCookie("volume", volume);
  }, [volume]);



  useEffect(() => {
    let timeOut;
    if (music) {
      let text = `En ce moment sur FunAdos : ${music.artist} - ${music.title} ⏵`;
      titleAnimation(text, timeOut);
    }
    return function cleanup() {
      if (timeOut != null) {
        clearTimeout(timeOut);
      }
    };
  }, [music]);


  useEffect(() => {
    const socket = socketIOClient(server)
    socket.on("musicChange", data => setMusic(data))
    socket.on("historyChange", data => setHistory(data))
  }, [server])




  return (
    <div className="container">
      <Background server={server}/>
      <div className="header">
        <Header/>
        <Dedicace/>
      </div>
      <Pochette
        music={music}
        history={history}
        error={error}
        
        /* Play/pause */
        isPlaying={isPlaying}
        togglePlaying={togglePlaying}

        /* Volume */
        changeVolume={(volume) => {
          setVolume(volume);
        }}
        volume={volume}
      />
      {/* <Habo/> */}
    </div>
  );
}

export default Player;
