import axios from "axios"
import { useEffect, useState } from "react";
import socketIOClient  from "socket.io-client"
import "../styles/background.scss";
import "../styles/layout.scss";



function Background({server}) {

  const apiUrl = `${server}/api`

  const initData = {
    information: "Chargement en cours..",
    music: {
        artist: "FunAdos",
        title: "La radio officielle d'HabboCity",
        cover: 'https://funados-radio.fr/export/pochette.png',
    }
  }
  const [music, setMusic] = useState(initData.music)


  const updateMusic = async () => {
    await axios
      .get(`${apiUrl}/music`, 
      { headers: {'Access-Control-Allow-Origin': '*'} })
      .then((res) => {
          if (res.data) {
              setMusic(res.data)
          }
      })
      .catch(_ => {
        setMusic(initData.music)
      })
  }

  useEffect(() => {
    updateMusic()
  }, []);


  useEffect(() => {
    const socket = socketIOClient(server)
    socket.on("musicChange", data => setMusic(data))
  }, [])



  return (
    <div className="background-container">
      <div
        className="background"
        style={{
          backgroundImage: `url(${music.cover})`,
        }}
      >
        <div className="gradient-filter"></div>
        <div className="black-filter"></div>
      </div>
    </div>
  );
}

export default Background;
