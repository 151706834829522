import "../styles/header.scss"

function Header() {
  return (
    <div className="radio-info">
      <div className="radio-name" aria-label="Fun Ados la radio officielle d'HaboCity"></div>
    </div>
  );
}

export default Header;
