import "../styles/pochette.scss";
import { useEffect, useState } from "react";
import Slider from "react-input-slider";

function formatTimeString(starttime) {
  const startTimeSafari = starttime.replace(/-/g, '/') //compatibilité Safari
  const date = new Date(startTimeSafari);
  const minutes = date.getMinutes();
  return date.getHours() + "H" + (minutes < "10" ? "0" : "") + minutes;
}

function Pochette(props) {

  const {music, history, error} = props

  const [showPochette, setShowPochette] = useState({opacity: '1'})
  const [showPochetteFilter, setShowPochetteFilter] = useState({opacity: '0.3'})
  const [pochetteStart, setPochetteStart] = useState(true)


  //Gestion d'affichage au lancement
  useEffect(() => {
    if (pochetteStart) {
        setTimeout(() => {
          setPochetteStart(false)
            setShowPochette({})
            setShowPochetteFilter({})
        }, 10000)
      }
  }, [pochetteStart])

  return (
    <div className="body">
      <div className="pochette">
        <div className="pochette-controls-container">
          <div className="pochette-image-container">
            <div>
              <div
                className={`black-filter ${props.isPlaying ? "" : "displayed"}`}
                style={showPochetteFilter}
              ></div>
              <img
                className="pochette-image"
                src={music.cover}
              ></img>
            </div>
            <div
              className={`play-button ${props.isPlaying ? "displayPause" : ""}`}
              style={showPochette}
              onClick={(event) => {
                event.stopPropagation();
                props.togglePlaying();
              }}
            ></div>
          </div>
          <div className="slider" style={showPochette}>
            <Slider
              axis="x"
              xstep={0.01}
              xmax={1}
              x={props.volume}
              onChange={({ x }) => props.changeVolume(x)}
              styles={{
                active: {
                  backgroundColor: "white",
                },
              }}
            />
          </div>
        </div>
        <div className="description">
          <div className="direct">
            <span>EN DIRECT</span>
          </div>
          <div className="artist">{music.artist}</div>
          <div className="title">{music.title}</div>
        </div>
        {
          error ? null : 
          <div className="historic">
            {history.length > 0 && history.map((music) => (
              <div className="music" key={music.title}>
                <img src={music.cover}></img>
                <div className="historic-description">
                  <div className="starttime">
                    {formatTimeString(music.starttime)}
                  </div>
                  <div className="artist">{music.artist}</div>
                  <div className="title">{music.title}</div>
                </div>
              </div>
            ))}
          </div>
          }
      </div>
    </div>
  );
}

export default Pochette;
